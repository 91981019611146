var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-card',{attrs:{"outlined":""}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-multiline people-general-information"},[_c('div',{staticClass:"column is-12"},[_c('h3',{staticClass:"board-title"},[_vm._v("General Information")])]),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"office email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] && 'is-danger',"message":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Office Email "),_c('b-tooltip',{attrs:{"position":"is-right","multilined":"","label":"Only use email that has already been assigned in Space-ID"}},[_c('b-icon',{attrs:{"icon":"help-circle","size":"is-small"}})],1)]},proxy:true}],null,true)},[(_vm.isEdit)?_c('b-input',{attrs:{"disabled":true},model:{value:(_vm.data.work_email),callback:function ($$v) {_vm.$set(_vm.data, "work_email", $$v)},expression:"data.work_email"}}):_c('b-autocomplete',{attrs:{"placeholder":"e.g. johndoe@roketin.com","icon-right":"chevron-down","open-on-focus":true,"data":_vm.workEmailList,"check-infinite-scroll":true},on:{"select":_vm.onSelectEmail,"input":_vm.emitData},model:{value:(_vm.data.work_email),callback:function ($$v) {_vm.$set(_vm.data, "work_email", $$v)},expression:"data.work_email"}})],1)]}}])})],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"label":"Is Admin"}},[_c('b-checkbox',{attrs:{"true-value":"1","false-value":"0","checked":1},model:{value:(_vm.data.is_admin),callback:function ($$v) {_vm.$set(_vm.data, "is_admin", $$v)},expression:"data.is_admin"}})],1)],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"First Name","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. John"},on:{"input":_vm.emitData},model:{value:(_vm.data.first_name),callback:function ($$v) {_vm.$set(_vm.data, "first_name", $$v)},expression:"data.first_name"}})],1)]}}])})],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{staticClass:"optional",attrs:{"label":"Middle Name"}},[_c('b-input',{attrs:{"placeholder":"e.g. Michael"},on:{"input":_vm.emitData},model:{value:(_vm.data.middle_name),callback:function ($$v) {_vm.$set(_vm.data, "middle_name", $$v)},expression:"data.middle_name"}})],1)],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Last Name","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. Doe"},on:{"input":_vm.emitData},model:{value:(_vm.data.last_name),callback:function ($$v) {_vm.$set(_vm.data, "last_name", $$v)},expression:"data.last_name"}})],1)]}}])})],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{staticClass:"optional",attrs:{"label":"Nick Name"}},[_c('b-input',{attrs:{"placeholder":"e.g. John"},on:{"input":_vm.emitData},model:{value:(_vm.data.nick_name),callback:function ($$v) {_vm.$set(_vm.data, "nick_name", $$v)},expression:"data.nick_name"}})],1)],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"no. ktp","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"No. KTP","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. 1234567890123456"},on:{"input":_vm.emitData},model:{value:(_vm.data.ktp),callback:function ($$v) {_vm.$set(_vm.data, "ktp", $$v)},expression:"data.ktp"}})],1)]}}])})],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{staticClass:"optional",attrs:{"label":"Nationality"}},[_c('b-input',{attrs:{"placeholder":"e.g. Indonesia"},on:{"input":_vm.emitData},model:{value:(_vm.data.nationality),callback:function ($$v) {_vm.$set(_vm.data, "nationality", $$v)},expression:"data.nationality"}})],1)],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"timezone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Timezone","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"rules":"required","placeholder":"Select a Time zone","expanded":""},on:{"input":_vm.emitData},model:{value:(_vm.data.timezone),callback:function ($$v) {_vm.$set(_vm.data, "timezone", $$v)},expression:"data.timezone"}},_vm._l((_vm.listTimezones),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option + '' + _vm.getTimezonesOffset(option))+" ")])}),0)],1)]}}])})],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{staticClass:"optional",attrs:{"label":"Birth Place"}},[_c('b-input',{attrs:{"placeholder":"e.g. Jakarta"},on:{"input":_vm.emitData},model:{value:(_vm.data.place_of_birth),callback:function ($$v) {_vm.$set(_vm.data, "place_of_birth", $$v)},expression:"data.place_of_birth"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Date of Birth","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Select a date","min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"input":_vm.emitData},model:{value:(_vm.data.date_of_birth),callback:function ($$v) {_vm.$set(_vm.data, "date_of_birth", $$v)},expression:"data.date_of_birth"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Gender","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"expanded":"","placeholder":"e.g Male"},on:{"input":_vm.emitData},model:{value:(_vm.data.gender),callback:function ($$v) {_vm.$set(_vm.data, "gender", $$v)},expression:"data.gender"}},[_c('option',{attrs:{"value":"M"}},[_vm._v("Male")]),_c('option',{attrs:{"value":"F"}},[_vm._v("Female")])])],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"blood type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Blood Type","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"expanded":"","placeholder":"e.g AB"},on:{"input":_vm.emitData},model:{value:(_vm.data.blood_type),callback:function ($$v) {_vm.$set(_vm.data, "blood_type", $$v)},expression:"data.blood_type"}},[_c('option',{attrs:{"value":"a"}},[_vm._v("A")]),_c('option',{attrs:{"value":"b"}},[_vm._v("B")]),_c('option',{attrs:{"value":"ab"}},[_vm._v("AB")]),_c('option',{attrs:{"value":"o"}},[_vm._v("O")])])],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"religion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Religion","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"expanded":"","placeholder":"e.g Islam"},on:{"input":_vm.emitData},model:{value:(_vm.data.religion),callback:function ($$v) {_vm.$set(_vm.data, "religion", $$v)},expression:"data.religion"}},[_c('option',{attrs:{"value":"islam"}},[_vm._v("Islam")]),_c('option',{attrs:{"value":"katholik"}},[_vm._v("Katholik")]),_c('option',{attrs:{"value":"kristen"}},[_vm._v("Kristen")]),_c('option',{attrs:{"value":"buddha"}},[_vm._v("Buddha")]),_c('option',{attrs:{"value":"hindu"}},[_vm._v("Hindu")]),_c('option',{attrs:{"value":"konghuchu"}},[_vm._v("Konghuchu")])])],1)]}}])})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"contact number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Contact Number","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. 081234567890"},on:{"input":_vm.emitData},model:{value:(_vm.data.personal_phone),callback:function ($$v) {_vm.$set(_vm.data, "personal_phone", $$v)},expression:"data.personal_phone"}})],1)]}}])})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Email","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. johndoe@gmail.com"},on:{"input":_vm.emitData},model:{value:(_vm.data.personal_email),callback:function ($$v) {_vm.$set(_vm.data, "personal_email", $$v)},expression:"data.personal_email"}})],1)]}}])})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Address","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. Jalan Kenangan No. 25"},on:{"input":_vm.emitData},model:{value:(_vm.data.address),callback:function ($$v) {_vm.$set(_vm.data, "address", $$v)},expression:"data.address"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"rt","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"RT","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. 001"},on:{"input":_vm.emitData},model:{value:(_vm.data.rt),callback:function ($$v) {_vm.$set(_vm.data, "rt", $$v)},expression:"data.rt"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"rw","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"RW","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. 001"},on:{"input":_vm.emitData},model:{value:(_vm.data.rw),callback:function ($$v) {_vm.$set(_vm.data, "rw", $$v)},expression:"data.rw"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"postal code","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Postal Code","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. 40225"},on:{"input":_vm.emitData},model:{value:(_vm.data.postal_code),callback:function ($$v) {_vm.$set(_vm.data, "postal_code", $$v)},expression:"data.postal_code"}})],1)]}}])})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"City","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. Bandung"},on:{"input":_vm.emitData},model:{value:(_vm.data.city),callback:function ($$v) {_vm.$set(_vm.data, "city", $$v)},expression:"data.city"}})],1)]}}])})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Province","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. Jawa Barat"},on:{"input":_vm.emitData},model:{value:(_vm.data.province),callback:function ($$v) {_vm.$set(_vm.data, "province", $$v)},expression:"data.province"}})],1)]}}])})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"District","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. Leuwigajah"},on:{"input":_vm.emitData},model:{value:(_vm.data.district),callback:function ($$v) {_vm.$set(_vm.data, "district", $$v)},expression:"data.district"}})],1)]}}])})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"village","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Village","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g. Cimahi Selatan"},on:{"input":_vm.emitData},model:{value:(_vm.data.village),callback:function ($$v) {_vm.$set(_vm.data, "village", $$v)},expression:"data.village"}})],1)]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }