<template>
  <r-card outlined>
    <div class="columns">
      <div class="column is-12">
        <div class="columns is-multiline people-general-information">
          <div class="column is-12">
            <h3 class="board-title">General Information</h3>
          </div>
          <div class="column is-10">
            <ValidationProvider
              name="office email"
              v-slot="{ errors }"
              rules="required|email"
            >
              <b-field :type="errors[0] && 'is-danger'" :message="errors[0]">
                <template #label>
                  Office Email
                  <b-tooltip
                    position="is-right"
                    multilined
                    label="Only use email that has already been assigned in Space-ID"
                  >
                    <b-icon icon="help-circle" size="is-small"></b-icon>
                  </b-tooltip>
                </template>

                <b-input
                  v-if="isEdit"
                  v-model="data.work_email"
                  :disabled="true"
                ></b-input>
                <b-autocomplete
                  v-else
                  v-model="data.work_email"
                  placeholder="e.g. johndoe@roketin.com"
                  icon-right="chevron-down"
                  :open-on-focus="true"
                  :data="workEmailList"
                  :check-infinite-scroll="true"
                  @select="onSelectEmail"
                  @input="emitData"
                ></b-autocomplete>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-2">
            <b-field label="Is Admin">
              <b-checkbox
                v-model="data.is_admin"
                true-value="1"
                false-value="0"
                :checked="1"
              ></b-checkbox>
            </b-field>
          </div>
          <div class="column is-12">
            <ValidationProvider
              name="first name"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="First Name"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. John"
                  v-model="data.first_name"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <b-field label="Middle Name" class="optional">
              <b-input
                placeholder="e.g. Michael"
                v-model="data.middle_name"
                @input="emitData"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-12">
            <ValidationProvider
              name="last name"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Last Name"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. Doe"
                  v-model="data.last_name"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <b-field label="Nick Name" class="optional">
              <b-input
                placeholder="e.g. John"
                v-model="data.nick_name"
                @input="emitData"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-12">
            <ValidationProvider
              name="no. ktp"
              v-slot="{ errors }"
              rules="required|numeric"
            >
              <b-field
                label="No. KTP"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. 1234567890123456"
                  v-model="data.ktp"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <b-field label="Nationality" class="optional">
              <b-input
                placeholder="e.g. Indonesia"
                v-model="data.nationality"
                @input="emitData"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-12">
            <ValidationProvider
              name="timezone"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Timezone"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-select
                  rules="required"
                  placeholder="Select a Time zone"
                  v-model="data.timezone"
                  expanded
                  @input="emitData"
                >
                  <option
                    v-for="option in listTimezones"
                    :value="option"
                    :key="option"
                  >
                    {{ option + '' + getTimezonesOffset(option) }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-6">
            <b-field label="Birth Place" class="optional">
              <b-input
                placeholder="e.g. Jakarta"
                v-model="data.place_of_birth"
                @input="emitData"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <ValidationProvider
              name="date of birth"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Date of Birth"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-datepicker
                  placeholder="Select a date"
                  :min-date="minDate"
                  :max-date="maxDate"
                  v-model="data.date_of_birth"
                  @input="emitData"
                ></b-datepicker>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-4">
            <ValidationProvider
              name="gender"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Gender"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-select
                  v-model="data.gender"
                  expanded
                  placeholder="e.g Male"
                  @input="emitData"
                >
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-4">
            <ValidationProvider
              name="blood type"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Blood Type"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-select
                  v-model="data.blood_type"
                  expanded
                  placeholder="e.g AB"
                  @input="emitData"
                >
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="ab">AB</option>
                  <option value="o">O</option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-4">
            <ValidationProvider
              name="religion"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Religion"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-select
                  v-model="data.religion"
                  expanded
                  placeholder="e.g Islam"
                  @input="emitData"
                >
                  <option value="islam">Islam</option>
                  <option value="katholik">Katholik</option>
                  <option value="kristen">Kristen</option>
                  <option value="buddha">Buddha</option>
                  <option value="hindu">Hindu</option>
                  <option value="konghuchu">Konghuchu</option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <ValidationProvider
              name="contact number"
              v-slot="{ errors }"
              rules="required|numeric"
            >
              <b-field
                label="Contact Number"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. 081234567890"
                  v-model="data.personal_phone"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <ValidationProvider
              name="email"
              v-slot="{ errors }"
              rules="required|email"
            >
              <b-field
                label="Email"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. johndoe@gmail.com"
                  v-model="data.personal_email"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <ValidationProvider
              name="address"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Address"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. Jalan Kenangan No. 25"
                  v-model="data.address"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-4">
            <ValidationProvider
              name="rt"
              v-slot="{ errors }"
              rules="required|numeric"
            >
              <b-field
                label="RT"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. 001"
                  v-model="data.rt"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-4">
            <ValidationProvider
              name="rw"
              v-slot="{ errors }"
              rules="required|numeric"
            >
              <b-field
                label="RW"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. 001"
                  v-model="data.rw"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-4">
            <ValidationProvider
              name="postal code"
              v-slot="{ errors }"
              rules="required|numeric"
            >
              <b-field
                label="Postal Code"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. 40225"
                  v-model="data.postal_code"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-6">
            <ValidationProvider
              name="city"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="City"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. Bandung"
                  v-model="data.city"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-6">
            <ValidationProvider
              name="province"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Province"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. Jawa Barat"
                  v-model="data.province"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-6">
            <ValidationProvider
              name="district"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="District"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. Leuwigajah"
                  v-model="data.district"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-6">
            <ValidationProvider
              name="village"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Village"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  placeholder="e.g. Cimahi Selatan"
                  v-model="data.village"
                  @input="emitData"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </r-card>
</template>
<script>
export default {
  props: ['propsData', 'emails', 'allEmails', 'isEdit', 'listTimezones'],
  data() {
    const today = new Date()
    return {
      data: {
        id: null,
        ktp: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        nick_name: '',
        allWorkEmails: null,
        work_email: null,
        personal_email: '',
        personal_phone: '',
        place_of_birth: '',
        date_of_birth: null,
        nationality: '',
        gender: null,
        blood_type: null,
        religion: null,
        address: '',
        rt: '',
        rw: '',
        district: '',
        village: '',
        postal_code: '',
        city: '',
        province: '',
        timezone: null,
        is_admin: 0,
      },
      activeStep: 0,
      isStepsClickable: true,
      selectedReportedToInfo: null,
      minDate: new Date(
        today.getFullYear() - 100,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    }
  },
  mounted() {
    this.data = this.propsData
    console.log('component', this.emails)
  },

  computed: {
    workEmailList() {
      return this.emails?.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.data.work_email?.toLowerCase()) >= 0
        )
      })
    },
  },
  methods: {
    emitData() {
      this.$emit('on-input-typed', this.data)
    },

    onSelectEmail(option) {
      this.selected = option
      let emailSelected = this.allEmails.filter((option) => {
        return option.workEmail === this.selected
      })
      if (emailSelected[0]) {
        this.idEmail = emailSelected[0].id
        this.$emit('on-select-email', this.idEmail)
      }
    },
  },
}
</script>
